






















import { Component, Vue, Prop } from 'vue-property-decorator';
import { ITimestamp } from '@/types/common';

@Component({ name: 'TimestampButtons' })
export default class TimestampButtons extends Vue {
  @Prop() public readonly timestamps!: Array<ITimestamp>;
}
